import logo from './logo.svg';
import './App.css';
import { ARShades_PD_Interface } from '@spaarkly-srl/arshades_arpdmeter_web';
// import CustomStyles from './style/CustomStyle.module.css'
import React from 'react';

function App() {

  const handleResult= (result) => {
    console.log("Result:", result);
    // Puoi fare qualcosa con il risultato qui
  };


  return (
    <ARShades_PD_Interface
    licenseToken={"U2FsdGVkX1+Uc6bKzMyW0QL/WLXlgxsWJjd+ooRa4JSmmR1wT0fw69iedpn/OEpE" }
    onResultAvailable = {handleResult}
    // customClass={{primaryColor: "blue", backgroundColorProps:"black", fontColorPrimary:"black"}}
  />
 
  );
}

export default App;
